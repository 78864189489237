@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/SourceSans3-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('./fonts/SourceSans3-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./fonts/SourceSans3-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('./fonts/SourceSans3-BoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./fonts/SourceSans3-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('./fonts/SourceSans3-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./fonts/SourceSans3-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('./fonts/SourceSans3-BlackItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('./fonts/SourceSans3-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('./fonts/SourceSans3-ExtraBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('./fonts/SourceSans3-ExtraLight.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('./fonts/SourceSans3-ExtraLightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./fonts/SourceSans3-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('./fonts/SourceSans3-MediumItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('./fonts/SourceSans3-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'SourceSans';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('./fonts/SourceSans3-SemiBoldItalic.ttf') format('truetype');
  }
}

body {
  margin: 0;
  font-family: 'SourceSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width: 640px) {
  html {
    font-size: 70%;
  }
}
